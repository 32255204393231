import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { useLocation } from "@reach/router"

const Header = () => {
  const location = useLocation()
  const [activeNav, setActiveNav] = useState(0)

  const FuboSportsLogo =
    "https://assets-imgx.fubo.tv/marketing/lp/images/fsn/fsn-logo-white.png?w=300&ch=width&auto=format,compress"

  useEffect(() => {
    const { pathname } = location
    if (pathname === "/") {
      setActiveNav(0)
    } else if (pathname.includes("series")) {
      setActiveNav(1)
    } else if (pathname.includes("contact")) {
      setActiveNav(4)
    }
  }, [location])

  return (
    <HeaderElement>
      <HeaderLogo>
        <Link to="/">
          <img src={FuboSportsLogo} alt="fubo sports logo" />
        </Link>
      </HeaderLogo>

      <HeaderNav>
        <ul>
          <li className={activeNav === 0 ? "active" : ""}>
            <Link to="/">Watch</Link>
          </li>
          <li className={activeNav === 1 ? "active" : ""}>
            <Link to="/series">Series</Link>
          </li>
          <li className={activeNav === 4 ? "active" : ""}>
            <Link to="/contact">Contact</Link>
          </li>
        </ul>
      </HeaderNav>
    </HeaderElement>
  )
}

export default Header

const HeaderElement = styled.header`
  background: #141313;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px;
  border-bottom: 1px solid rgba(104, 104, 104, 0.3);
  gap: 10px;

  @media (max-width: 576px) {
    flex-direction: column;
    background: #151718;
  }

  a {
    text-decoration: none;
    color: rgb(255, 255, 255);
    font-size: 1.25rem;
    font-weight: 500;
    letter-spacing: 0px;
    text-align: center;
    text-transform: uppercase;

    @media (max-width: 768px) {
      font-size: 1.13rem;
    }

    @media (max-width: 576px) {
      font-size: 0.9rem;
    }
  }
`

const HeaderLogo = styled.div`
  img {
    max-width: 80px;

    @media (min-width: 768px) {
      max-width: 100px;
    }
  }
`

const HeaderNav = styled.nav`
  ul {
    display: flex;
    position: relative;
    padding: 0;
    gap: 20px;
  }

  li {
    position: relative;
  }

  li.active:after {
    content: "";
    height: 5px;
    background: rgb(250, 70, 22);
    position: absolute;
    bottom: -10px;
    left: 0;
    right: 0;
    margin: auto;

    @media (max-width: 576px) {
      bottom: -15px;
    }
  }

  @media (min-width: 768px) {
    .active-nav-bar {
      width: 75px;
      left: 35px;
    }
  }
`
